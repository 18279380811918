import React, { Component } from "react";
import Slider from "react-slick";
import smartPhonesOne from "./../../../images/grupo/Cell_1.png";
import smartPhonesTwo from "./../../../images/grupo/Cell_2.png";
import smartPhonesThree from "./../../../images/grupo/Cell_3.png";
import smartPhonesFour from "./../../../images/grupo/Cell_4.png";
import smartPhonesFive from "./../../../images/grupo/Cell_5.png";

const TecnologiaExclusivaMobile = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return <div>
        <Slider {...settings} className={"text-center cellphones-slider"}>
            <div className={"col-md"}>
                <img src={smartPhonesOne} className={"img-fluid mx-auto"}/>
            </div>
            <div>
                <img src={smartPhonesTwo} className={"img-fluid mx-auto"}/>
            </div>
            <div>
                <img src={smartPhonesThree} className={"img-fluid mx-auto"}/>
            </div>
            <div>
                <img src={smartPhonesFour} className={"img-fluid mx-auto"}/>
            </div>
            <div>
                <img src={smartPhonesFive} className={"img-fluid mx-auto"}/>
            </div>
        </Slider>
    </div>
}

export default TecnologiaExclusivaMobile;
