import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {UseViewPort} from "../../../hooks/useViewPort";
import agenciaPersonas from "./../../../images/agencia/agencia-personas.png";

const TuMejorEleccion = () => {
    const isMobile = UseViewPort() < 767;

    return <div className={"bg-white text-justify"}>
        <Container className={"py-2"}>
            <Row>
                <Col xs={12} md className={"mt-3 mb-2 my-md-4 d-md-flex align-items-center tu-mejor-eleccion-img"}>
                    {isMobile ? <h2>Somos tu mejor
                        <span className={"text-yellow"}> elección</span></h2> : null}
                </Col>
                <Col xs={12} md={7} className={"my-4"}>
                    {!isMobile ? <h2>Somos tu mejor
                        <span className={"d-block text-yellow"}>elección</span></h2> : null}
                    <p>
                        Durante más de 80 años, hemos acumulado experiencia, prestigio y fiabilidad ante la
                        comunidad durante la cual hemos servido, ofreciendo soluciones integrales, prácticas y
                        personalizadas, pero sobre todo, hemos cumplido con la orientación total a la
                        satisfacción de nuestros clientes, basándonos en nuestros principios. Tus socios en
                        comercio exterior.
                    </p>
                </Col>
            </Row>
        </Container>
    </div>
}

export default TuMejorEleccion;
