import "./ContactStrip.scss";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {getExchange} from "../../utils/exchange";
import React from "react";
const ContactStripMobile = ({exchange, customEmail = ''}) => {

    const [exchangePrice, setExchangePrice] = useState({
        rate_1: 0,
        rate_2: 0,
        updated_date_1: (new Date().toLocaleDateString("es-ES", { month: 'long', day: 'numeric' })),
        updated_date_2: (new Date().toLocaleDateString("es-ES", { month: 'long', day: 'numeric' }))
    });

    useEffect(() => {
        if(exchange){
            getExchange().then((data) => {
                setExchangePrice(data);
            });
        }
    } ,[exchange])


    return <div className={"contact-strip"}>
        <Container>
            {exchange ? <Row className={"pt-2 pt-md-3"}>
                    <Col xs={12} xxl={11} className={"mx-auto"}>
                        <Row className={"exchange text-center"}>
                            <Col xs={7}>
                                <Row>
                                    <Col xs={12} className={"mb-2 mb-lg-0 label"}>Tipo de cambio</Col>
                                    <Col xs={12} lg className={"mb-2"}>
                                        <Row>
                                            <Col xs={8}>
                                                <span className={"date d-block w-100"}>{exchangePrice.updated_date_1}</span>
                                            </Col>
                                            <Col xs={4}>
                                                <span className={"price"}>
                                                    ${exchangePrice.rate_1}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} lg className={"mb-3"}>
                                        <Row>
                                            <Col xs={8}>
                                                <span className={"date d-block w-100"}>{exchangePrice.updated_date_2}</span>
                                            </Col>
                                            <Col xs={4}>
                                                <span className={"price"}>
                                                    ${exchangePrice.rate_2}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className={"mobile-details text-left"}>
                                <div className={"mb-2"}>
                                    <strong className={"d-block"}>Cotíza vía E-mail</strong>
                                    <a href={"mailto:negocios@aar.com.mx"}>negocios@aar.com.mx</a>
                                </div>
                                <div className={"mb-2"}>
                                    <strong className={"d-block"}>Llámanos | Cotizaciones</strong>
                                    <a href={"tel:526646831022"}>+52 (664) 683 1022</a>
                                </div>
                                <div>
                                    <strong className={"d-block"}>Horario- Sucursales</strong>
                                    Lunes - Viernes&nbsp;&nbsp; 8 am - 5:30 pm
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row> :
                <Row className={"py-3 px-sm-5 text-center"}>
                    <Col xs={12} sm={10} md={11} className={"mx-auto"}>
                        <Row>
                            {
                                customEmail.length === 0 && <React.Fragment>
                                    <Col xs={6} className={"px-3"}>
                                        <div className={"icon at-email text-left w-100"}>
                                            <strong className={"d-block"}>Cotíza vía E-mail</strong>
                                            <a href={"mailto:negocios@aar.com.mx"}>negocios@aar.com.mx</a>
                                        </div>
                                    </Col>
                                    <Col xs={6} className={"px-3"}>
                                        <div className={"icon phone-call text-left"}>
                                            <strong className={"d-block"}>Llámanos | Cotizaciones</strong>
                                            <a href={"tel:526646831022"}>+52 (664) 683 1022</a>
                                        </div>
                                    </Col>

                                    <Col xs={6} className={"mt-3 px-0 mx-auto mt-3"}>
                                        <div className={"icon home-location text-left"}>
                                            <strong className={"d-block"}>Horario - Sucursales</strong>
                                            Lunes - Viernes 8am - 5:30pm
                                        </div>
                                    </Col>

                                </React.Fragment>
                            }

                            {
                                customEmail.length !== 0 && <React.Fragment>
                                    <Col xs={10} sm={8} className={"px-3 mx-auto"}>
                                        <div className={"icon at-email text-left w-100"}>
                                            <strong className={"d-block"}>Contáctanos</strong>
                                            <a href={"tel:526646831022"}>+52 (664) 683 1022</a> <span className={"mx-2"}>/</span> <a href={`mailto:${customEmail}`}>{customEmail}</a>
                                        </div>
                                    </Col>
                                    <Col xs={8} sm={7} className={"mt-3 px-0 mx-auto"}>
                                        <div className={"icon home-location text-left"}>
                                            <strong className={"d-block"}>Horario - Sucursales</strong>
                                            Lunes - Viernes 8am - 5:30pm
                                        </div>
                                    </Col>
                                </React.Fragment>
                            }
                        </Row>
                    </Col>
                </Row>
            }
        </Container>
    </div>;
}

export default ContactStripMobile;