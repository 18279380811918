import React  from "react";
import Slider from "react-slick";
import {Col, Row, Container} from "react-bootstrap";
import "./Afiliaciones.scss";

const Afiliaciones = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }
        ]
    };
    const list = ["coparmex", "caaarem", "index", "canacintra", "dimbc"];

    return <Container className={"py-3 py-md-4 py-lg-5"} id={"afiliaciones"}>
        <Row>
            <Col>
                <h2 className={"pb-2"}>Afiliaciones a organismos empresariales</h2>
            </Col>
        </Row>
        <Row id={"listado-afiliaciones"}>
            <Col className={"mx-auto"}>
                <Slider {...settings} className={"text-center mobile-tecnologia"}>
                    {
                        list.map( (name) => <div className={`afiliacion-item ${name}`}/>)
                    }
                </Slider>
            </Col>
        </Row>
        <Row className={"pt-2 pt-md-3 pt-lg-5"}>
            <Col>
                <h2 className={"pb-2"}>Apoyo a grupos o instituciones</h2>
            </Col>
        </Row>
        <Row id={"instituciones-afiliadas"}>
            <Col className={"mx-auto text-center"}>
                <div className={"institucion-item hospital-californias"}/>
                <div className={"institucion-item opera-en-la-calle"}/>
            </Col>
        </Row>
    </Container>
}

export default Afiliaciones;