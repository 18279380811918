import "./FlipCard.scss";

const FlipCard = ({ name = null, front = null, back = null }) => {
    return <div className={`flip-card ${name}`}>
        <div className="flip-card-inner">
            <div className="flip-card-front">{front}</div>
            <div className="flip-card-back">{back}</div>
        </div>
    </div>;
}

export default FlipCard;
