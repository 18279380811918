import {Container, Row, Col} from "react-bootstrap";
import Certificaciones from "../inicio/Certificaciones";
import "./Fortalezas.scss";

const Fortalezas = () => {
    return <Container className={"py-4 py-md-5 agencia-fortalezas"}>
        <Row>
            <Col md={9} className={"offset-md-2"}>
                <h2 className={"mb-1 mb-md-4"}>Nuestras fortalezas:</h2>

                <ul className={"check-mark-list"}>
                    <li><span>Creación de eficiencia, seguridad y soluciones de impacto</span></li>
                    <li><span>Personal experimentado y especializado en materia aduanal</span></li>
                    <li><span>Mejorar el desempeño de la cadena de suministro</span></li>
                    <li><span>Soluciones integrales e innovadoras</span></li>
                    <li><span>Gestión de calidad</span></li>
                    <li><span>Tecnología integrada y adaptable</span></li>
                    <li><span>Certificaciones con mejora continua</span></li>
                </ul>
            </Col>
        </Row>

        <Row>
            <Col xs={12} className={"pt-4 text-center"}>
                <h2 className={"mx-auto"}>Certificaciones:</h2>
            </Col>
            <Col xs={12} md={9} className={"mx-auto p-lg-0"}>
                <Certificaciones hideText={true} hover={true}/>
            </Col>
        </Row>
    </Container>
}

export default Fortalezas;
