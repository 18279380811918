import {Container, Row, Col} from "react-bootstrap";
import "./PlataformaTecnologica.scss";
import logo from '../../../images/agencia/agencia-logo-white.png';

const PlataformaTecnologica = () => {
    return <div className={"plataforma-tecnologica"}>
        <Container className={"py-2 py-md-5"}>
            <Row className={"pb-2 pb-md-5 text-center"}>
                <h2 className={"mb-5 mb-md-0"}>Plataforma tecnológica</h2>
            </Row>
            <Row>
                <Col xs={11} md={11} xl={7} className={"mx-auto"}>
                    <Row className={"d-flex align-items-stretch align-self-stretch"}>
                        <Col xs={12} md={6} className={"mb-4 mb-md-0"}>
                            <div className={"plataforma-box"} onClick={() => window.open("https://portal.aar.com.mx/aar_portal/")}>
                                <div className={"top-box mb-3"}>
                                    <Row>
                                        <Col xs={11} md={8} className={"mx-auto"}>
                                            <Row>
                                                <Col xs={12} className={"mb-4 text-center"}>
                                                    <img src={logo} className={"w-75"} alt={"Grupo Logístico Rodríguez"}/>
                                                </Col>
                                                <Col xs={12} className={"mb-2"}>
                                                    <div className={"input-blank"}>Usuario</div>
                                                </Col>
                                                <Col xs={12} className={"mb-2"}>
                                                    <div className={"input-blank"}>Contraseña</div>
                                                </Col>
                                                <Col xs={12}>
                                                    <div className={"input-btn"}>Entrar</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={"bottom-box"}>
                                    <p className={"text-justify pb-0 mb-0"}>
                                        <strong>Nuestro sistema de seguimiento</strong> a operaciones vía internet, les permitirá conocer el tiempo real del estatus <strong>de sus operaciones.</strong>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} >
                            <div className={"plataforma-box"} onClick={() => window.open("https://portal.aar.com.mx/aar_portal/")}>
                                <div className={"top-box mb-4"}>
                                    <Row>
                                        <Col xs={12} md={9} lg={8} className={"mx-auto"}>
                                            <Row>
                                                <Col xs={12} className={"mb-4 text-center"}>
                                                    <img src={logo} className={"w-75"} alt={"Grupo Logístico Rodríguez"}/>
                                                </Col>
                                                <Col xs={12} className={"mb-2 text-center"}>
                                                    <div className={"input-btn-round d-inline"}>Inicio</div>
                                                </Col>
                                                <Col xs={12} className={"mb-2 text-center letter-spacing"}>
                                                    Dashboard
                                                </Col>
                                                <Col xs={12} xl={11} className={"text-center mx-auto px-0"}>
                                                    <div className={"inbox blank"}>29</div>
                                                    <div className={"inbox viewed"}>11</div>
                                                    <div className={"inbox time"}>17</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={"bottom-box"}>
                                    <p className={"text-justify pb-0 mb-0"}>
                                        El Portal de Servicios Digitales integra las capacidades de impresión en sitio, así como una serie de reportes y estadísticas útiles para los importadores y exportadores sobre sus trámites y revisiones que Grupo Logístico Rodríguez provee en forma atractiva y útil.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
}
export default PlataformaTecnologica;
