import {Col, Container, Row} from "react-bootstrap";
import cotizarImage1 from '../../../images/inicio/cotizar-aa.jpg';
import cotizarImage2 from '../../../images/inicio/cotizar-ff.jpg';
import cotizarImage4 from '../../../images/inicio/cotizar-integral.jpg';
import cotizarImage3 from '../../../images/inicio/cotizar-transporte.jpg';
import OwlCarousel from "react-owl-carousel";
import {useRef, useState, useEffect}  from "react";
import {UseViewPort} from "../../../hooks/useViewPort";

const CotizaAhoraMobile = () => {
    const isMobile = UseViewPort() < 767;
    const serviciosRef = useRef();
    const [index, setIndex] = useState(0);

    console.log(isMobile);
    useEffect( () => {
        let carousel = serviciosRef.current;
        carousel.to(index, 500);
    }, [index]);

    const goToService = ( newIndex )=> {
        setIndex(newIndex);
    }

    return <Container>
        <Row className={"p-0"}>
            <Col className={"mx-auto"} md={11}>
                <Row className={"contact-box"}>
                    <Col className={"pt-3"} xs>
                        <h3 className={"text-yellow"}><span className={"d-md-block"}>Cotiza ahora para agilizar </span>
                            tu solicitud </h3>
                        <p className={"py-md-2"}><span className={"d-md-block"}>Selecciona el modo, introduzca los detalles y pronto </span>
                            nos contactaremos con usted.</p>
                    </Col>
                    <Col className={"d-md-inline-block p-0"} md={6}>
                        <OwlCarousel className="owl-theme centered-dots owl-large-dots" margin={0}
                                     nav={false} mergeFit={true} dots={true} loop={true}
                                     items={1} autoplay={false} speed={500} ref={serviciosRef} mouseDrag={false} touchDrag={false}>
                            <img src={cotizarImage1} alt={"Agencia Aduanal Rodríguez"}/>
                            <img src={cotizarImage2} alt={"Freight Forwarding"}/>
                            <img src={cotizarImage3} alt={"Integral"}/>
                            <img src={cotizarImage4} alt={"International Transport"}/>
                        </OwlCarousel>
                    </Col>
                    <Col className={'pb-3 pt-3'}>
                        <ul className={"options m-0 p-0"}>
                            <li>
                                <input type="radio" name="process" id={"process-adr"} checked={index === 0}  onChange={()=>goToService(0)}/>
                                <label htmlFor="process-adr">Agencia Aduanal Rodríguez</label>
                            </li>
                            <li>
                                <input type="radio" name="process" id={"process-rr-ff"} checked={index === 1} onChange={()=>goToService(1)}/>
                                <label htmlFor="process-rr-ff">R&R International Freight Forwarding</label>
                            </li>
                            <li>
                                <input type="radio" name="process" id={"process-rr-it"} checked={index === 2} onChange={()=>goToService(2)}/>
                                <label htmlFor="process-rr-it">R&R International Transport</label>
                            </li>
                            <li>
                                <input type="radio" name="process" id={"process-si"} checked={index === 3} onChange={()=>goToService(3)}/>
                                <label htmlFor="process-si">Servicio Integral</label>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12} className={"mt-2"}>
                        <button className={"btn-filled"} onClick={() => window.open("/agencia/cotizacion")}>Continuar</button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>;
}

export default CotizaAhoraMobile;