import AccesoDesktop from "./acceso/AccesoDesktop";
import AccesoMobil from "./acceso/AccesoMobil";

import {UseViewPort} from "../../hooks/useViewPort";
import logoTransporteMobile from "../../images/acceso/logo-transporte.png";
import logoAgenciaMobile from "../../images/acceso/logo-agencia.png";
import logoFreightMobile from "../../images/acceso/logo-freight.png";
import logoFreightDesktop from "./../../images/acceso/portal-freight-forwarding.png";
import logoAgenciaDesktop from "./../../images/acceso/portal-agencia.png";
import logoTransporteDesktop from "./../../images/acceso/portal-transportes.png";

const Acceso = () => {
    const isMobile = UseViewPort() < 767;
    const accesos = [
        {
            title: 'Portal Transportes',
            url: 'https://www.softwareparatransporte.com/GMTERPV8_WEB/ES/PAGE_CatUsuariosLoginAWP.awp',
            image: isMobile ? logoTransporteMobile : logoTransporteDesktop,
            bgAlias: 'transporte'
        },
        {
            title: 'Portal Agencia',
            url: 'https://portal.aar.com.mx/aar_portal/',
            image: isMobile ? logoAgenciaMobile : logoAgenciaDesktop,
            bgAlias: 'agencia-glr'
        },
        {
            title: 'Portal Almacén',
            url: 'http://aar.systemscomm.net/sirius/Login',
            image: isMobile ? logoFreightMobile : logoFreightDesktop,
            bgAlias: 'freight'
        }
    ]
    return <div>
        {isMobile ? <AccesoMobil accesos={accesos}/> : <AccesoDesktop accesos={accesos}/>}
    </div>
}

export default Acceso;