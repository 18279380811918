import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import "./SolicitaCotizacion.scss";
import modelosImg from "./../../../images/clientes/modelos.png";
import {UseViewPort} from "../../../hooks/useViewPort";

const SolicitaCotizacion = () => {
    const isMobile = UseViewPort() < 767;

    const TextComponent = () => {
        return <React.Fragment>
            <Col xs={4} lg={4} xl={3} className={"d-flex align-self-center px-2 py-3 p-sm-4 py-3 py-md-5 flex-column"}>
                <h2><span className={"d-block"}>Solicita una </span>
                    cotización.</h2>
                <p>
                    <span>Llena nuestro formulario, </span>
                    <span>envíanos un correo o mensaje </span>
                    <span>de WhatsApp y nuestro equipo </span>
                    <span>comercial se pondrá en contacto </span>
                    contigo para revisar tu proyecto.
                </p>
            </Col>
            <Col xs={3} md={2} lg={3} xl={3} className={"d-flex align-self-center flex-column px-0 px-md-2 py-5 md-md-2"}>
                <a href={`${process.env.PUBLIC_URL}/agencia/cotizacion`} className={"btn-rounded formulario"}>Acceso a Formulario</a>
                <a href={`mailto:negocios@aar.com.mx`} className={"btn-rounded outlook"} rel={"noreferrer"}>Mensaje en Outlook</a>
                <a href={`https://api.whatsapp.com/send?phone=526644102854`} target="_blank" rel={"noreferrer"} className={"btn-rounded whatsapp"}>Mensaje en Whatsapp</a>
            </Col>
        </React.Fragment>;
    }

    const ImageComponent = () => {
        return <React.Fragment>
            <Col xs={5} md xl className={"position-relative strip text-center"}>
                <img src={modelosImg} alt={"Solicita Cotizacion"} className={"w-100"}/>
            </Col>
        </React.Fragment>;
    }

    return <div id={"solicita-cotizacion"} className={"bg-light-blue"}>
        <div className={"mt-md-4"}>
            <Container>
                <Row>
                    <Col>
                        <Row className={"justify-content-between"}>
                            <TextComponent/>
                            <ImageComponent/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
}

export default SolicitaCotizacion;
