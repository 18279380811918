import {Col, Container, Row} from 'react-bootstrap';
import logo from '../../images/footer-logo.png';
import "./Footer.scss";
import {useEffect, useState, useRef} from "react";
import {IsViewportVisible} from "./../../hooks/IsViewportVisible";
import React from "react";

const Footer = () => {
    const [scrollMap, setScrollMap] = useState();
    const mapRef = useRef();
    const year = new Date().getFullYear();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            let bounding = mapRef.current.getBoundingClientRect();
            setScrollMap(IsViewportVisible(bounding));
        });
    }, [mapRef]);
    
    return <footer className={`pt-4 pt-lg-4 pb-4`}>
        <Container className={"px-lg-5 px-3 px-md-0"}>
            <Row className={"px-lg-4 main-info"}>
                <Col xs={12} md={4} className={"text-center text-md-left"}>
                    <h5 className={"mb-2 mb-lg-3-half"}>Soluciones</h5>
                    <ul>
                        <li><a href={"/agencia"} className={"text-reset"}>Grupo Logístico Rodríguez</a></li>
                        <li><a href={"/international-transport"} className={"text-reset"}>R&R International Transport</a></li>
                        <li><a href={"/freight-forwarding"} className={"text-reset"}>R&R International Freight
                            Forwarding</a></li>
                    </ul>
                </Col>
                <Col xs={1} className={"d-none d-md-block divisor-vertical"}/>

                <Col xs={12} md={4} className={"text-center text-md-left"}>
                    <h5 className={"mb-2 mb-lg-4"}>Atención a clientes</h5>
                    <Row>
                        <Col xs={11} md className={"mx-auto"}>
                            <ul className={"customer-service icon-lists"}>
                                <li className={`phone`}>
                                    <a href={"tel:+526646831022"}>+52 (664) 683 1022</a></li>
                                <li className={`working-hours`}>8 am - 5:30 pm de Lunes a Viernes</li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1} className={"d-none d-md-block divisor-vertical"}/>

                <Col xs={12} md className={"pt-md-5 mt-2 pl-5 pl-md-0"}>
                    <ul className={"icon-lists mx-auto mb-4"} style={{ width: 'max-content'}}>
                        <li className={`info`}><a href={`${process.env.PUBLIC_URL}/nosotros`}>Nosotros</a></li>
                        <li className={`at-email`}><a href={`${process.env.PUBLIC_URL}/contacto`}>Contacto</a></li>
                        {/*<li className={`home-location`}><a href={`${process.env.PUBLIC_URL}/#ubicacion`}>Ubicación</a></li>*/}
                    </ul>
                </Col>
                {process.env.REACT_APP_ENABLE_LANG === true && <React.Fragment>
                    <Col xs={1} className={"divisor-vertical"}/>
                    <Col xs={5} md={2} className={"pt-md-4 mt-3 md-lg-0"}>
                        <ul className={"mt-lg-4 language-list icon-lists"}>
                            <li className={`${scrollMap ? 'scroll': ''}`}><a href={`${process.env.PUBLIC_URL}/#ingles`}>Inglés</a></li>
                            <li className={`active ${scrollMap ? 'scroll': ''}`}><a href={`${process.env.PUBLIC_URL}/#espanol`}>Español</a></li>
                        </ul>
                    </Col>
                </React.Fragment>}
            </Row>
            <Row className={"my-1"}>
                <hr/>
            </Row>
            <Row className={"px-lg-4 addresses"}>
                <Col md={12} className={"mx-auto"}>
                    <Row className={"d-flex flex-column flex-md-row"}>
                        <Col xs={8} md className={"mb-3 mb-md-0 px-lg-4 mx-auto"}>
                            <Row className={"d-flex align-items-center"}>
                                <Col xs={12} className={"mt-2 mt-md-0"}>
                                    <h5>Tijuana</h5>
                                </Col>
                                <Col className={"pl-md-1 address-info"}>
                                    <div className={`address mt-2 mt-md-3 ${scrollMap ? 'scroll': ''}`} ref={mapRef}>
                                        Blvd. 3ra Oeste No. 17500-C<br/>
                                        Fracc. Garita de Otay,<br/>
                                        C.P: 22430 Tijuana, B.C.
                                    </div>
                                    <div className={`phone mt-2 mt-md-3 ${scrollMap ? 'scroll': ''}`}>
                                        Ph: +52 (664) 683 1022<br/>
                                        Ph: +52 (664) 683 1023<br/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={8} md className={"mb-3 mb-lg-0 px-lg-4 mx-auto"}>
                            <Row className={"d-flex align-items-center"}>
                                <Col xs={12} className={"mt-2 mt-lg-0"}>
                                    <h5>Ensenada</h5>
                                </Col>
                                <Col className={"address-info"}>
                                    <div className={`address mt-2 mt-lg-3 ${scrollMap ? 'scroll': ''}`}>
                                        Virgilio Uribe 649 Local 11<br/>
                                        Plaza Delfines<br/>
                                        C.P. 22800, Ensenada, B.C.
                                    </div>
                                    <div className={`phone mt-2 mt-lg-3 ${scrollMap ? 'scroll': ''}`}>
                                        Ph: +52 (646) 156 5392<br/>
                                        Ph: +52 (646) 175 7206<br/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={8} md className={"mb-3 mb-lg-0 px-lg-4 mx-auto"}>
                            <Row className={"d-flex align-items-center"}>
                                <Col xs={12} className={"mt-2 mt-lg-0"}>
                                    <h5>San Diego</h5>
                                </Col>
                                <Col className={"address-info"}>
                                    <div className={`address mt-2 mt-lg-3 ${scrollMap ? 'scroll': ''}`}>
                                        2450 Siempre Viva Ct. <br/>
                                        San Diego, CA. 92154.
                                    </div>
                                    <div className={`phone mt-0 mt-lg-3 ${scrollMap ? 'scroll': ''}`} ref={mapRef}>
                                        Ph: +1 (619) 661 0027<br/>
                                        Fax: +1 (619) 661 0044<br/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <Container>
            <Row className={"pt-3 pb-4 py-lg-4"}>
                <Col className={"small-logo"}>
                    <img src={logo} className={"footer-logo"} alt={"Rodríguez - Logo"}/>
                </Col>
                <Col className={"d-lg-flex justify-content-md-end align-items-md-center col-md-4 col-12"}>
                    <Row className={"social-networks text-center"}>
                        <Col xs={12}>Redes Sociales</Col>
                        <Col xs={12}>
                            <ul className={"m-0"}>
                                <li><a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_LINK_LINKEDIN}`}  title="LinkedIn"  className={"linkedin gray-scale"}>LinkedIn</a></li>
                                <li><a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_LINK_FB}`} title="Facebook" className={"facebook gray-scale"}>Facebook</a></li>
                                <li><a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_LINK_YOUTUBE}`}  title="YouTube"  className={"youtube gray-scale"}>YouTube</a></li>
                                <li><a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_LINK_TWITTER}`} title="Twitter"  className={"twitter gray-scale"}>Twitter</a></li>
                            </ul>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <Container fluid className={"copyright py-2 py-lg-0"}>
            <Container className={"py-2"}>
            <Row className={"d-flex flex-row-reverse flex-md-row"}>
                <Col xs={12} md>
                    <p className={"p-0 m-0"}><span className={"d-block"}>&copy;{year} Copyright Grupo Logístico Rodríguez.</span>
                        Todos los derechos reservados.</p>
                </Col>
                <Col xs={12}  md={4} className={"aviso-privacidad d-md-flex justify-content-md-end align-items-md-center col-md-4 col-12"}>
                    <a target={"_blank"}  rel="noreferrer" href={'/aviso-de-privacidad'} title={"Aviso de privacidad"}>Aviso de privacidad</a>
                </Col>
            </Row>
            </Container>
        </Container>
    </footer>
}

export  default Footer;
