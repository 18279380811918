import i18n from "i18next";
import React, {useEffect, useState} from 'react';
import {Col, Collapse, Container, Dropdown, Nav, Navbar, Row, Tab, Tabs} from "react-bootstrap";
import "./NavPills.scss";
import "./HeaderAcceso.scss";
import "./../Header.scss";
import HeaderContacto from "./HeaderContacto";
import {UseViewPort} from "../../../hooks/useViewPort";

const HeaderAcceso = (props) => {
    const [scroll, setScroll] = useState();
    const [open, setOpen] = useState(false);

    const isDesktop = UseViewPort() >= 768;

    const [toggle, setToggle] = useState(false);
    const handleClickChangeLang = (lang) => {
        console.log(lang);
    }

    const toggleMenu = () => {
        setToggle(!toggle);
        setOpen(false);
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 75);
        });
    }, []);

    return (<header>
        <Navbar bg="clear" variant="dark" expand="lg">
            <Container className={`${isDesktop ? 'flex-nowrap justify-content-end' : ''}`}>
                <Navbar.Brand href={`${process.env.PUBLIC_URL}/`} className={"acceso-portal py-4 mt-1 py-md-4"}>
                    <h1>Acceso al <span>portal digital</span></h1>
                </Navbar.Brand>
                <Navbar.Collapse id="rodriguez-navbar"
                                 className={"justify-content-end flex-column  flex-wrap navbar-collapse collapse show"}>
                    <Nav className="me-auto">
                        <Nav.Link href={`${process.env.PUBLIC_URL}/`} key={"inicio"}>Inicio</Nav.Link>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/contacto`} key={"contacto"}>Contacto</Nav.Link>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/agencia/cotizacion`}
                                  key={"cotizar"}>Cotizar</Nav.Link>
                        <Nav.Link href={`${process.env.PUBLIC_URL}/acceso`} key={"acceso-glr"}>
                            <div className={"acceso-glr"}>Acceso GLR</div>
                        </Nav.Link>
                        {process.env.REACT_APP_ENABLE_LANG === true && <React.Fragment>
                            <div className="navbar-nav ml-auto nav-link">
                                <Dropdown>
                                    <Dropdown.Toggle variant="clear" className={"nav-item"}>
                                        <i className={`language ${i18n.language === "en" ? 'lang-en' : 'lang-es'}`}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            className={`dropdown-item ${i18n.language === 'en' ? 'active' : ''}`}
                                            onClick={() => handleClickChangeLang("en")}>English</Dropdown.Item>
                                        <Dropdown.Item
                                            className={`dropdown-item ${i18n.language === 'es' ? 'active' : ''}`}
                                            onClick={() => handleClickChangeLang("es")}>Español</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </React.Fragment>}


                        <div className="navbar-nav ml-auto second-menu">
                            <button className={"nav-extra"} onClick={() => toggleMenu()}/>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <Container fluid
                   className={`px-0 px-md-2 menu-toggle ${(toggle) ? 'toggled' : ''} ${props.exchange ? 'main-toggled' : ''}`}>
            <Container className={"p-0 py-md-4"}>
                <Tabs
                    variant="pills"
                    defaultActiveKey="servicios"
                    transition={false}
                    className="mb-md-3"
                >
                    <Tab eventKey="servicios" title="Servicios">
                        <Row className={"px-3 px-md-0 mt-md-4"}>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6><a href={"/agencia"} className={"text-reset"}>Grupo Logístico Rodríguez</a></h6>
                                    <ul>
                                        <li>
                                            <a onClick={() => setOpen(!open)}
                                               className={`collapsible-menu ${ open ? 'open' : 'close'}`}
                                               aria-controls="collapse-nonsotros-submenu"
                                               aria-expanded={open} >Nosotros</a>
                                            <Collapse in={open}>
                                                <ul className={"mb-0"} id={"collapse-nonsotros-submenu"}>
                                                    <li className={"mt-2"}>
                                                        <a href={"/nosotros"}>Quiénes somos</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/grupo"}>Grupo</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/servicios"}>Servicios</a>
                                                    </li>
                                                </ul>
                                            </Collapse>

                                        </li>
                                        <li>
                                            <a href={"/historia"}>Historia</a>
                                        </li>
                                        <li>
                                            <a href={"/clientes"}>Clientes</a>
                                        </li>
                                        <li>
                                            <a href={"/contacto"}>
                                                Contacto
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6><a href={"/international-transport"} className={"text-reset"}>R&R International Transport</a></h6>
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a href={"#servicios-logisticos"}>*/}
                                        {/*        Servicios logísticos*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href={"/contacto"}>
                                                Contacto
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6><a href={"/freight-forwarding"} className={"text-reset"}>R&R International Freight
                                        <span className={"d-block"}>Forwarding</span></a></h6>
                                    <ul>
                                        {/*<li>*/}
                                        {/*    <a href={"#servicios-logisticos"}>*/}
                                        {/*        Servicios logísticos*/}
                                        {/*    </a>*/}
                                        {/*</li>*/}
                                        <li>
                                            <a href={"/contacto"}>
                                                Contacto
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6><a href={"/acceso"} className={"text-reset"}>Acceso GLR</a></h6>
                                    <ul>
                                        <li>
                                            <a href={"https://portal.aar.com.mx/aar_portal"} rel={"noreferrer"} target={"_blank"}>
                                                Portal de servicios digitales
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"http://aar.systemscomm.net/sirius/Login"} rel={"noreferrer"} target={"_blank"}>
                                                Warehouse management system
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://www.softwareparatransporte.com/GMTERPV8_WEB/ES/PAGE_CatUsuariosLoginAWP.awp"} rel={"noreferrer"} target={"_blank"}>
                                                Portal de seguimiento transporte
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="cotizacion" title="Empleo GLR">
                        <Row className={"px-3 px-md-0 mt-md-4"}>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>Grupo Logístico Rodríguez</h6>
                                    <ul>
                                        <li>
                                            <a href={"/empleo"}>
                                                Información de vacantes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>R&R International Transport</h6>
                                    <ul>
                                        <li>
                                            <a href={"/empleo"}>
                                                Información de vacantes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} md className={"menu-list"}>
                                <div>
                                    <h6>R&R International Freight
                                        <span className={"d-block"}>Forwarding</span></h6>
                                    <ul>
                                        <li>
                                            <a href={"/empleo"}>
                                                Información de vacantes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="contacto" title="Contacto">
                        <HeaderContacto/>
                    </Tab>
                </Tabs>
            </Container>
        </Container>
    </header>);
};

export default HeaderAcceso;
