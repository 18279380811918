import {Col, Container, Row} from "react-bootstrap";
import Contador from "../grupo/Contador";
import React from "react";

const QuienesSomos = () => {
    return <div>
        <div className={"quienes-somos py-3 py-md-4 py-lg-5 text-justify"}>
            <Container>
                <Row>
                    <Col xs={7} md={5}>
                        <h2>¿Quiénes somos?</h2>
                        <p>Almacén localizado en San Diego, autorizado como Zona Libre de Comercio (FTZ/Inbond), para
                            servicios de recepción, almacenamiento y reexpedición de mercancías en ambos lados de la
                            frontera.</p>
                        <p>Comprometidos en optimizar el tiempo y flujo de mercancía de cada operación de nuestros clientes,
                            también brindamos soporte para la toma de decisiones logísticas.</p>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className={"quienes-somos-counters bg-light-gray"}>
            <Container className={"py-4 py-md-4 py-lg-5"}>
                <Row>
                    <Col xs={10} md={12} className={"mx-auto"}>
                        <Row>
                            <Col xs={12} md>
                                <Contador duration={3} sign={"+"} number={"40000"}
                                          description={"Pies cuadrado de Almacén"}/>
                                <div className={"valores mt-2 mt-md-3 mt-lg-5 text-justify"}>
                                    <h5>Profesionalismo</h5>
                                    <p className={"mb-0 p-0"}>
                                        El servicio que ofrecemos nos distingue de nuestros clitenes, siendo un
                                        integrador logístico armonizado.</p>
                                    <p>
                                        Creadores de una solución única de negocio para el incremento sostenido del
                                        desempeño de nuestros clientes.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={8} className={"mx-auto d-md-none"}>
                                <hr/>
                            </Col>
                            <div className={"d-none d-md-block p-md-0 px-md-2 divisor-vertical-gray transparency"}/>

                            <Col xs={12} md>
                                <Contador duration={3} sign={"+"} number={"45000"}
                                          description={"Transacciones al año"}/>
                                <div className={"valores mt-2 mt-md-3 mt-lg-5 text-justify"}>
                                    <h5>Tiempo de respuesta y
                                        <span className={"d-block"}>seguridad</span></h5>

                                    <p>
                                        Nos especializamos en optimizar flujos y tiempos de respuesta, integrando
                                        esquemas de prevención de riesgo para incrementar la seguridad de los despachos.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={8} className={"mx-auto d-md-none"}>
                                <hr/>
                            </Col>
                            <div className={"d-none d-md-block p-md-0 px-md-2 divisor-vertical-gray transparency"}/>

                            <Col xs={12} md>
                                <Contador duration={3} number={"20"} description={"PUERTAS DE CARGA"}/>
                                <div className={"valores mt-2 mt-md-3 mt-lg-5 text-justify"}>
                                    <h5>Solución y logística
                                        <span className={"d-block"}>integral</span></h5>
                                    <p>
                                        Nuestro personal está ampliamente calificado y especializado para analizar
                                        necesidades operativas de cada uno de nuestros clientes y ofrecer la mejor
                                        solución y estrategia logística.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
}

export default QuienesSomos;
