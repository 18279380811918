import {Col, Container, Row} from "react-bootstrap";
import "./NuestraExperiencia.scss";
import Certificaciones from "./../inicio/Certificaciones";

const NuestraExperiencia = () => {
    return (<div>
        <Container className={"p-4 py-sm-3 py-xl-5"}>
            <Row className={"nuestra-experiencia"}>
                <Col xs={6} xl={4} className={"mx-auto text-center"}>
                    <div className={"vertical-line"}>
                        <div className={"vertical-block mb-5"}>
                            <p>Nuestra experiencia</p>
                        </div>
                        <div className={"vertical-block py-2"}>
                            <strong>82 AÑOS</strong>
                            <p className={"p-0 m-0 text-small"}>Creando valor para nuestros clientes</p>
                        </div>
                        <div className={"vertical-block mt-5"}>
                            <p>Mejora continua</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Certificaciones hideText={true}/>
                </Col>
            </Row>
        </Container>
    </div>);
}

export  default NuestraExperiencia;
