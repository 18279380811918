import React from "react";
import {Col, Row} from "react-bootstrap";

const SuccessMessage = ({logo, alt}) => {
    return <React.Fragment>
        <Row>
            <Col xs={7} className={"mx-auto"}>
                <img
                    src={logo}
                    alt={alt}
                    className={"img-fluid mb-5"}
                />
            </Col>
        </Row>
        <Row className={"text-center"}>
            <h4 className={"mb-4"}>
                ¡Tu solicitud ha sido enviada con éxito!
            </h4>
            <p className={"mx-auto d-block text-center"}>
                Nuestro equipo de atención a clientes revisará la información
                <span className={"d-block"}> y se comunicará contigo a la brevedad. </span>
            </p>
        </Row>
    </React.Fragment>
}

export default SuccessMessage;