import Footer from "../UI/Footer";
import Header from "../UI/Header";

const NotFound = () =>  {
    return <div>
        <Header/>
        NotFound
        <Footer/>
    </div>
}

export  default NotFound;