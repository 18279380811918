import React, { Suspense } from "react";
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
const rootElement = document.getElementById("root");


ReactDOM.render(
    <Suspense fallback="...">
        <App />
    </Suspense>,
    rootElement
);