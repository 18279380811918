import {Col, Container, Row} from "react-bootstrap";
import Contador from "./Contador";
import "./Contador.scss";
import React from "react";

const Transporte = () => {
    return <div id={"counter-strip"} className={"transporte"}>
        <Container className={"py-2 py-md-5"}>
            <Row className={"d-flex flex-row-reverse"}>
                <Col xs={8} md={7} lg={6} className={"py-3 px-4 p-md-2 p-lg-5"}>
                    <Row className={"justify-content-end"}>
                        <Col lg={9} className={"p-md-0"}>
                            <h2 className={"px-md-2"}>Transporte</h2>
                            <p className={"px-md-2"}>Principales puntos de servicio: Tijuana, Ensenada, San Diego, Los Ángeles County, Orange County, San Bernardino County, Inland Empire County y Utah.</p>
                        </Col>
                    </Row>
                    <Row className={"text-center justify-content-end"}>
                        <Col md={11} className={"px-md-0"}>
                            <Row>
                                <Col>
                                    <Contador duration={3} number={"40"} sign={"+"} description={"Equipos de transporte"} />
                                </Col>
                                <Col>
                                    <Contador duration={3} number={"24"} sign={"+"} description={"Años de experiencia"}/>
                                </Col>
                                <Col className={"mr-md-0 pr-md-0"}>
                                    <Contador duration={3} number={"54"} sign={"+"} description={`<span className={"d-block"}>Rutas nacionales e</span>
                                internacionales`}/>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Transporte;
