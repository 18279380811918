import {Col, Container, Row} from "react-bootstrap";
import valueOne from "../../../images/nosotros/time.png";
import valueTwo from "../../../images/nosotros/hands.png";
import valueThree from "../../../images/nosotros/lock.png";
import valueFour from "../../../images/nosotros/cloud.png";
import valueFive from "../../../images/nosotros/certs.png";
import React from "react";
import "./CoreValues.scss";

const CoreValues = ({className = ''}) => {
    return <div className={`core-values ${className}`}>
        <Container className={"py-3 pt-lg-5 pb-lg-4"}>
            <Row>
                <Col>
                    <h2 className={"text-yellow"}>Cadena de Valor</h2>
                    <p>Hacemos la diferencia a nuestros clientes, distinguiendo y ofreciendo:</p>
                </Col>
            </Row>
        </Container>
        <div className={"icons"}>
            <Container className={"py-5"}>
                <Row>
                    <Col className={"mx-auto text-center"} xs={10} sm={12} lg={className === 'freight-forwarding' ? 12 : 11} xl={className === 'freight-forwarding' ? 11: 10}>
                        <Row>
                            <Col xs={4} sm>
                                <div className={"icon-value"} style={{backgroundImage: `url(${valueOne})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    <span className={"d-md-block"}>Excelente tiempo </span>
                                    de respuesta
                                </h6>
                            </Col>
                            <div className={"d-none d-md-block p-md-0 px-md-2 divisor-vertical-yellow transparency"}/>
                            <Col xs={4} sm>
                                <div className={"icon-value"} style={{backgroundImage: `url(${valueTwo})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    <span className={"d-md-block"}>Atención </span>personalizada
                                </h6>
                            </Col>
                            <div className={"d-none d-md-block p-md-0 px-md-2 divisor-vertical-yellow transparency"}/>
                            <Col xs={4} sm>
                                <div className={"icon-value"} style={{backgroundImage: `url(${valueThree})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    <span className={"d-md-block"}>Seguridad y gestión </span>
                                    de riesgo
                                </h6>
                            </Col>
                            <div className={"d-none d-md-block p-md-0 px-md-2 divisor-vertical-yellow transparency"}/>
                            <Col xs={4} sm className={"mx-auto"}>
                                <div className={"icon-value"} style={{backgroundImage: `url(${valueFour})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    <span className={"d-md-block"}>Tecnología y Asesoría </span>
                                    especializada.
                                </h6>
                            </Col>
                            <div className={"d-none d-md-block p-md-0 px-md-2 divisor-vertical-yellow transparency"}/>
                            <Col xs={4} sm className={"mx-auto"}>
                                <div className={"icon-value my-3"} style={{backgroundImage: `url(${valueFive})`}}/>
                                <h6 className={"text-yellow transparency"}>
                                    Certificaciones
                                </h6>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
}

export default CoreValues;
