import {Col, Container, Row} from "react-bootstrap";
import "./Inicio.scss";

import MainImage from '../../images/inicio/home-main.png';
import Certificaciones from "./inicio/Certificaciones";

import MainHeader from "../UI/MainHeader";
import PluginChat from "../UI/PluginChat";
import NoticiasCarousel from "./inicio/NoticiasCarousel";
import React  from "react";
import CotizaAhora from "./inicio/CotizaAhora";
import CotizaAhoraMobile from "./inicio/CotizaAhoraMobile";

import TusSocios from "./inicio/TusSocios";
import {UseViewPort} from "../../hooks/useViewPort";
import Footer from "../UI/Footer";
import ContactoShort from "./inicio/ContactoShort";

const Inicio = () => {
    const viewPort = UseViewPort();
    const isMobile = viewPort < 768;

    return <div>
        <MainHeader image={MainImage} exchange={true} type={"inicio"}/>
        <Container fluid>
            <Row>
                <Col xs={4} className={"service-block parent-hover p-3 p-md-5"} id={"service-one"}>
                    <h3><span className={"d-block"}>Agencia </span>
                        <span className={"d-block"}>Aduanal </span>Rodríguez</h3>
                    {/*<a href={`${process.env.PUBLIC_URL}/servicios`} className={"btn-filled"}>Información</a>*/}
                </Col>
                <Col xs={4} className={"service-block parent-hover p-3 p-md-5"} id={"service-two"}>
                    <h3>
                        <span className={"d-block"}>R&R </span>
                        <span className={"d-block"}>International </span>
                        Transport
                    </h3>
                    {/*<a href={`${process.env.PUBLIC_URL}/servicios`} className={"btn-filled"}>Información</a>*/}
                </Col>
                <Col xs={4} className={"service-block parent-hover p-3 p-md-5"} id={"service-three"}>
                    <h3>
                        <span className={"d-block"}>R&R </span>
                        <span className={"d-block"}>International </span>
                        <span className={"d-block"}>Freight </span>
                        Forwarding
                    </h3>
                    {/*<a href={`${process.env.PUBLIC_URL}/servicios`} className={"btn-filled"}>Información</a>*/}
                </Col>
            </Row>
        </Container>
        <Container fluid className={"main p-0"}>
            <div className={"cotiza-ahora py-md-4"}>
                { isMobile ? <CotizaAhoraMobile/> : <CotizaAhora/> }
            </div>
            <Container fluid className={"px-0 py-2 py-md-5 my-2 my-md-5"} style={{ position: 'relative', overflow: 'hidden'}}>
               <TusSocios isMobile={isMobile}/>
            </Container>
            <Container>
                <Row className={"pb-1 pb-md-2  mb-md-2"}>
                    <Col>
                        <h2>Información de Interés</h2>
                        <p>Te brindamos noticias, comunicados, avisos y circulares al día, para que estés bien informado.</p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={"news"}>
                <NoticiasCarousel/>
            </Container>
            <Container className={"py-3 py-md-5"}>
                <Row>
                    <Col md={11} className={"mx-auto"}>
                        <ContactoShort/>
                    </Col>
                </Row>

                <Row>
                    <hr/>
                </Row>
                <Row className={"mb-3 px-md-5 px-2"}>
                    <Col>
                        <Certificaciones/>
                    </Col>
                </Row>
            </Container>
            <PluginChat/>
        </Container>
        <Footer/>
    </div>
}

export default Inicio;
