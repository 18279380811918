import MainHeader from "../../UI/MainHeader";
import MainImage from '../../../images/clientes/clientes-main.jpg';
import Socios from "./Socios";
import NuestraExperiencia from "./../clientes/NuestraExperiencia";
import SolicitaCotizacion from "./../clientes/SolicitaCotizacion";
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";

const Grupo = () => {
    return(<div>
        <MainHeader image={MainImage} activeButton={true}/>
        <Socios/>
        <NuestraExperiencia/>
        <SolicitaCotizacion/>
        <PluginChat/>
        <Footer/>
    </div>);
}

export default Grupo;
