import {Container, Row, Col} from "react-bootstrap";

const InfoAdicional = () => {
    return <div className={"bg-white"}>
        <Container className={"py-4 py-md-5 info-adicional text-justify"}>
            <Row>
                <Col>
                    <h2 className={"mb-1 mb-md-4"}>Información adicional</h2>

                    <ul className={"circle-list"}>
                        <li><span>Control de bitácora electrónica para carga y descarga.</span></li>
                        <li><span>Monitoreo de los tiempos de respuesta mediante dispositivos visuales.</span></li>
                        <li><span>Maniobras especializadas.</span></li>
                        <li><span>Proceso para prevención de daños, cero tolerancia en faltantes o pérdidas.</span></li>
                        <li><span>Supervisión constante y auditada en los despachos de mercancías con visión remota para nuestros clientes.</span></li>
                        <li><span>Seguro para mercancías, alarma perimetral y video cámaras con monitoreo externo las 24 horas.</span></li>
                        <li><span>Coordinador de cuenta asignado con conocimiento de la estructura del cliente.</span></li>
                        <li><span>Disponibilidad para atender demanda y ejecución constante del proceso de despacho.</span></li>
                        <li><span>Integración de procedimiento operativo adaptable a las necesidades del cliente, enfocado a generar una mejora continua.</span></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </div>
}

export default InfoAdicional;
