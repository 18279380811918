import MainHeader from "../../UI/MainHeader";
import MainImage from '../../../images/historia/historia-main-family.jpeg';
import Timeline from "./Timeline";
import {Col, Container, Row} from "react-bootstrap";
import "./Historia.scss";
import thumbEmilio from '../../../images/historia/foto-emilio-rguez.png';
import thumbZambudio from '../../../images/historia/foto-zambudio.png';
import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import Logo2022 from '../../../images/historia/logo-2022.png';

const Historia = () => {

    return(<div>
        <MainHeader image={MainImage} activeButton={true}/>
        <div id="historia" className={"py-3 py-md-4 py-lg-5"}>
            <Container>
                <Row>
                    <Col className={"mx-auto"} lg={11}>
                        <h2 className={"pb-md-3"}>En Grupo Logístico Rodríguez hemos servido a la comunidad comercial e industrial desde 1940.</h2>
                        <p>Durante este tiempo nos hemos distinguido entre los mejores provedores de servicios aduanales por el alto nivel de profesionalismo y la certeza jurídica que ofrecemos a nuestros socios comerciales.</p>
                    </Col>
                    <Col xs={6} md={10} className={"pt-3 mx-auto text-center"}>
                        <Row>
                            <Col xs={12} md className={"historia-thumb"}>
                                <div className={"description"}>
                                    <img src={thumbEmilio} alt={"Emilio Rodríguez Hernández"}/>
                                </div>
                                <div className={"year"}>1967</div>
                                <p><span className={"d-block"}>Emilio Rodríguez </span>Hernández</p>
                            </Col>
                            <Col xs={12} md className={"historia-thumb"}>
                                <div className={"description"}>
                                    <img src={thumbZambudio} alt={"Rigoberto Rodríguez Zamudio"}/>
                                </div>
                                <div className={"year"}>1990</div>
                                <p><span className={"d-block"}>Rigoberto Rodríguez </span>Zamudio</p>
                            </Col>
                            <Col xs={12} md className={"historia-thumb"}>
                                <div className={"description"}>
                                    <img src={Logo2022} alt={"Actualidad"}/>
                                </div>
                                <div className={"year"}>Actual</div>
                                <p><span className={"d-block"}>Rigoberto Rodríguez </span>Fuentes</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container lg={10} className={"pt-3 p-md-5 mx-auto"}>
                <Row>
                    <Col md={10} lg={12} className={"mx-auto"}>
                        <Timeline/>
                    </Col>
                </Row>
            </Container>
        </div>
        <PluginChat/>
        <Footer/>
    </div>);
}

export default Historia;
