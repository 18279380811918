import {Container, Row, Col} from "react-bootstrap";
import FreightServicios from "./../../../images/freight/freight-servicios.png";
import FreightAlmacenaje from "./../../../images/freight/freight-almacenaje.png";
import FreightEmbarques from "./../../../images/freight/freight-embarques.png";
const Soluciones = () => {
    return <Container className={"soluciones py-4 pt-md-5"}>
        <Row>
            <Col md={11} className={"mx-auto"}>
                <Row className={"center"}>
                    <h2 className={"mb-4"}>Tenemos las soluciones que necesitas:</h2>
                </Row>
                <Row>
                    <Col>
                        <img src={FreightServicios} alt={"Servicios in bond FTZ"}/>
                        <p>Servicios in bond <span className={"d-block"}>FTZ</span></p>
                    </Col>
                    <Col>
                        <img src={FreightAlmacenaje} alt={"Almacenaje y distribución"}/>
                        <p>Almacenaje y<span className={"d-block"}>distribución</span></p>
                    </Col>
                    <Col>
                        <img src={FreightEmbarques} alt={"Liberación de embarques marítimos y aéreos"}/>

                        <p>Liberación de embarques <span className={"d-block"}>marítimos y aéreos</span></p>
                    </Col>
                </Row>
            </Col>
        </Row>

    </Container>
}

export default Soluciones;
