import ContactStrip from "./ContactStrip";
import ContactStripMobile from "./ContactStripMobile";

import Header from "./Header";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {UseViewPort} from "./../../hooks/useViewPort";

const MainHeader = ({image, exchange, activeButton, header, buttonSettings = {} , alignment = '', hideButton, type, customEmail = '', children}) => {
    let viewPort = UseViewPort();
    const isMobile = viewPort < 767;
    const isTablet = viewPort >= 768 && viewPort < 1025;

    const [scroll, setScroll] = useState();
    useEffect(() => {
        if(!isMobile){
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 75);
            });
        }
    }, [isMobile]);

    return <div className={`main-header ${type}`} style={{backgroundImage: `url(${image})`}}>
        <Header scroll={scroll} exchange={exchange | false}/>
        <Container className={`text-container my-1 my-lg-5 ${isMobile && 'text-center'}  ${exchange && isMobile ? '' : 'py-4'} header-container ${scroll ? 'scroll' : ''}`}>
            <Row className={`${alignment} align-items-center`}>
                <Col xs={12} lg={(type === 'contacto') ? 7 : 12}>
                    <Row className={"d-flex flex-column-reverse  flex-lg-column"}>
                        { (buttonSettings.hasOwnProperty('text') && !isTablet ) ? <Col xs md={12} className={"d-flex align-items-center align-items-lg-start"}>
                            <a className={`btn-filled btn-no-arrow d-block d-md-none d-lg-block mt-2 mt-md-3 mx-auto mx-lg-0 ${activeButton ? 'always-active' : null}`} href={`${buttonSettings.url}`}>{buttonSettings.text}</a>
                        </Col> : '' }
                        <Col xs md={12}>
                            {
                                header ?? <h4 className={"mt-2"}>
                                    <span className={"d-block"}>Nuestro compromiso:</span>
                                    <span className={"d-block"}>la excelencia de nuestro </span>
                                    <span className={"d-block"}>servicio</span>
                                </h4>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        {isMobile ? <ContactStripMobile exchange={exchange | false} customEmail={customEmail}/> : <ContactStrip exchange={exchange | false} customEmail={customEmail}/>}
    </div>;
}

export default MainHeader;
