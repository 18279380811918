import {Col, Container, Row} from "react-bootstrap";
import "./Sucursales.scss";
import mapa from "./../../../images/grupo/mapa.svg";

const Sucursales = () => {
    return <div id={"sucursales"}>
        <Container>
            <Row className={"d-flex flex-row"}>
                <Col xs={12} md={4} className={"py-3 p-lg-2 p-lg-5"}>
                    <div className={"py-2 px-2 px-md-0 py-xl-5"}>
                        <h2><span className={"d-block text-small mb-2 mb-lg-3"}>Sucursales en: </span>
                            <span className={"d-lg-block text-yellow"}>Tijuana, Ensenada </span>
                            <span className={"d-lg-block text-yellow"}>y San Diego </span></h2>
                        <p><span className={"d-block"}>Con presencia en:</span>
                            Mexicali, Manzanillo y Long Beach</p>
                    </div>
                </Col>
                <Col xs={12} md className={"right-map"}>
                    <iframe height={"100%"} width={"100%"} src={mapa} className={"w-100 mb-3 mb-lg-0"} alt={"Ubicaciones"}/>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Sucursales;
