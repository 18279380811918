import {Col, Container, Row} from "react-bootstrap";
import "./Operaciones.scss";

const Operaciones = () => {
    return <div id={"operaciones"}>
        <Container>
            <Row className={"d-flex"}>
                <Col xs={7} sm={6} xl={5}>
                    <h2><span className={"d-block"}>Operaciones </span>
                        en línea</h2>
                    <p>Alcance del expediente digital de tus operaciones las 24 horas los 7 días de la semana que te permite consultar el estatus de tus mercancías en cualquier momento.</p>
                    <a href={`${process.env.PUBLIC_URL}/acceso`} className={"btn-filled btn-rounded mt-md-4 d-inline-block"}>Consulta aquí</a>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Operaciones;