import "./ContactStrip.scss";
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getExchange} from "../../utils/exchange";

const ContactStrip =({exchange, customEmail = ''}) =>{
    const [exchangePrice, setExchangePrice] = useState({
        rate_1: 0,
        rate_2: 0,
        updated_date_1: (new Date().toLocaleDateString("es-ES", { month: 'long', day: 'numeric', year: "numeric" })),
        updated_date_2: (new Date().toLocaleDateString("es-ES", { month: 'long', day: 'numeric', year: "numeric" }))
    });

    useEffect(() => {
        if(exchange){
            getExchange().then((data) => {
                setExchangePrice(data);
            });
        }
    } ,[exchange])

    return <div className={"contact-strip"}>

            {exchange ? <Container><Row className={"pt-2 pt-md-3"}>
                <Col xs={12} xl={11} className={"mx-auto"}>
                    <Row className={"exchange  text-center"}>
                        <Col md={3} lg={2} xl={3} className={"mb-3 mb-md-0 mb-lg-0 label"}>Tipo de cambio</Col>
                        <Col xs={12} md>
                            <Row className={"d-flex flex-row-reverse flex-md-row"}>
                                <Col xs md={4}>
                                    <span className={"price"}>
                                        ${exchangePrice.rate_1}
                                    </span>
                                </Col>
                                <Col xs={7} md  lg={7}>
                                    <span className={"date d-block w-100"}>{exchangePrice.updated_date_1}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs className={"d-none d-md-block divisor-diagonal"}/>
                        <Col xs={12} md className={"mt-2 mt-md-0"}>
                            <Row className={"d-flex flex-row-reverse flex-md-row"}>
                                <Col xs md={4}>
                                    <span className={"price"}>
                                        ${exchangePrice.rate_2}
                                    </span>
                                </Col>
                                <Col xs={7} md lg={7}>
                                    <span className={"date d-block w-100"}>{exchangePrice.updated_date_2}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row></Container> : null}
        <Container>
            <Row className={"py-2 py-lg-4 px-md-0"}>
                <Col xs={12} className={"mx-auto px-md-0"}>
                    {
                        customEmail.length === 0 && <Row>
                            <Col xs={12} sm={6} md className={"my-3 my-lg-0 icon at-email"}>
                                <div className={"text-md-center"}>
                                    <strong className={"d-block"}>Cotíza vía E-mail</strong>
                                    <a href={"mailto:negocios@aar.com.mx"}>negocios@aar.com.mx</a>
                                </div>
                            </Col>
                            <Col xs className={"d-none d-md-block divisor-diagonal"}/>

                            <Col xs={12} sm={6} md className={"my-3 my-lg-0 icon phone-call"}>
                                <div className={"text-md-center"}>
                                    <strong className={"d-block"}>Llámanos | Cotizaciones</strong>
                                    <a href={"tel:526646831022"}>+52 (664) 683 1022</a>
                                </div>
                            </Col>
                            <Col xs className={"d-none d-md-block divisor-diagonal"}/>
                            <Col xs={12} sm={6} md className={"my-md-3 my-lg-0 mb-2 mb-lg-0 icon home-location mx-auto max-width"}>
                                <div className={"text-md-center"}>
                                    <strong className={"d-block"}>Horario- Sucursales</strong>
                                    Lunes - Viernes&nbsp;&nbsp; 8 am - 5:30 pm
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        customEmail.length > 0 && <Row>
                            <Col xs={12} sm={6} md className={"my-3 my-lg-0 icon at-email"}>
                                <div className={"text-md-center"}>
                                    <strong className={"d-block"}>Contáctanos</strong>
                                    <a href={"tel:526646831022"}>+52 (664) 683 1022</a> <span className={"mx-2"}>/</span> <a href={`mailto:${customEmail}`}>{customEmail}</a>
                                </div>
                            </Col>
                            <Col xs={1} className={"d-none d-md-block divisor-diagonal"}/>
                            <Col xs={12} sm={6} md={5} className={"my-md-3 my-lg-0 mb-2 mb-lg-0 icon home-location mx-auto max-width"}>
                                <div className={"text-md-center"}>
                                    <strong className={"d-block"}>Horario- Sucursales</strong>
                                    Lunes - Viernes&nbsp;&nbsp; 8 am - 5:30 pm
                                </div>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </Container>
    </div>;
}

export default ContactStrip;
