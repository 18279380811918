import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import "../../UI/SolicitaCotizacion.scss";
import logo from "../../../images/footer-logo.png";

const SolicitaCotizacion = () => {
    return <div className={"cotizacion-strip transporte"}>
        <Container className={"py-2 py-md-5"}>
            <Row className={"d-flex flex-row-reverse text-justify"}>
                <Col xs={8} md={7} lg={6} className={"py-3 px-4 p-md-2 p-lg-5"}>
                    <Row>
                        <Col lg={12} className={"p-md-0"}>
                            <Row className={"justify-content-end"}>
                                <Col md={8}>
                                    <h2>Solicita una
                                        <span className={"d-block"}>Cotización</span></h2>
                                    <p>Contamos con más de 24 años de experiencia en transporte, atendiendo necesidades
                                        específicas de nuestros clientes.</p>
                                    <button onClick={() => window.open('/international-transport/cotizacion', "_self")}
                                            className="btn-filled btn-yellow border-0">Cotiza aquí
                                    </button>
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </Col>
            </Row>
            <img src={logo} className={"cotizacion-logo"} alt={"Rodríguez - Logo"}/>
        </Container>
    </div>
}

export default SolicitaCotizacion;
