import * as yup from 'yup';

export const AgencyQuoteSchema = yup.object().shape({
    firstname: yup.string().required("El Nombre es requerido"),
    lastname: yup.string().required("El Apellido es requerido"),
    company: yup.string().required("La Empresa es requerida"),
    position: yup.string().required("El Puesto es requerido"),
    phone_office: yup.number().typeError("El Teléfono de Oficina es inválido").required("El Teléfono de Oficina es requerido"),
    phone_ext: yup.number().typeError("La Extensión es inválida"),
    phone_personal: yup.number().typeError("El Celular es inválido"),
    comments: yup.string().required("El Comentario es requerido").min(0).trim(),
    email: yup.string().email("El Correo Electrónico es inválido").required("El Correo Electrónico es requerido"),
    merchandise: yup.string().required("El Tipo de mercancía es requerido"),
    packaging: yup.string().required("El Embalaje es requerido"),
    weight: yup.string().required("El Peso es requerido"),
    operation: yup.string().required("La Operación es requerida"),
    company_type: yup.string().required("El Tipo de empresa es requerido"),
    importer: yup.string().required("El Padrón de importanciones es requerido"),
    shipments: yup.string().required("El Embarques mensuales es requerido"),
    shipments_parts: yup.string().required("Las Partes por embarque es requerida")
});

export const AgencyQuoteMoreThanTenSchema = yup.object().shape({
    firstname: yup.string().required("El Nombre es requerido"),
    lastname: yup.string().required("El Apellido es requerido"),
    company: yup.string().required("La Empresa es requerida"),
    position: yup.string().required("El Puesto es requerido"),
    phone_office: yup.number().typeError("El Teléfono de Oficina es inválido").required("El Teléfono de Oficina es requerido"),
    phone_ext: yup.number().typeError("La Extensión es inválida"),
    phone_personal: yup.number().typeError("El Celular es inválido"),
    merchandise: yup.string().required("El Tipo de mercancía es requerido"),
    operation: yup.string().required("La Operación es requerida"),
    importer: yup.string().required("El Padrón de importanciones es requerido"),
    comments: yup.string().required("El Comentario es requerido").min(0).trim(),
    email: yup.string().email("El Correo Electrónico es inválido").required("El Correo Electrónico es requerido")
});
