import PluginChat from "../../UI/PluginChat";
import Footer from "../../UI/Footer";
import {Col, Container, Row} from "react-bootstrap";
import Header from "../../UI/Header";
import "./VerNoticia.scss";
import {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import noticiaThumb from '../../../images/noticias/example-thumb.png';

function decodeHTMLEntities(text, counter) {
    const entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (let i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    if(counter > 1){
        return decodeHTMLEntities(text, (counter - 1) );
    }

    return text;
}

const decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    let element = document.createElement('div');

    function decodeHTMLEntities (str) {
        if(str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
})();

const VerNoticia = () => {
    const {id} = useParams();
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/news/${id}`).then((response) => {
            if(response.data.length>0){
                setInfo(response.data[0]);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            throw new Error("There was an error with api news");
        });
    },[id]);

    const getFormattedDate = (publishDate) => {
        const dateFragments = publishDate.split('-');
        return (new Date(dateFragments[0], dateFragments[1]-1, dateFragments[2])).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' });
    }

    return (<div>
        <Header bg={"bg-dark-gray"}/>

        { info && <div>
            <div id={"new-header"} style={{backgroundImage: `url(${info.image})`}}>
                <div className={"header-image"}>
                    <Container>
                        <Row>
                            <Col xs={6} lg={5} className={"px-3 px-md-0"}>
                                <h2 className={"pt-3 pb-0 py-sm-3 py-md-5"}>
                                    {info.title}
                                </h2>
                                <div className={"mb-md-2 mt-md-4 mt-md-5"}>
                                    <strong>Noticias de Prensa</strong>
                                    <p className={"mb-0"}>Todos los días, resumen de noticias sobre Comercio Exterior</p>
                                </div>
                                <div className={"mb-md-3"}>
                                    <strong className={"d-inline-block"}>Fuente: </strong>&nbsp;<p className={"d-inline-block"}>{info.source}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Container className={"py-3 py-sm-4 py-md-5"}>
                <Row>
                    <Col className={"new-body mb-3 px-3 px-md-0 mb-md-5"}>
                        <div dangerouslySetInnerHTML={{ __html: `${decodeHTMLEntities(info.content, 10)}` }} />
                    </Col>
                </Row>
                <Row className={"text-right new-info"}>
                    <Col>
                        <a href={info.source_link} className={"d-block new-source"} target={"_blank"} rel="noreferrer">Fuente: {info.source}</a>
                        <p>Fecha de publicación: {getFormattedDate(info.publish_date)}</p>
                    </Col>
                </Row>
            </Container>
        </div>}

        {(info===null && loading === false) && <Container className={"py-5 my-5 text-center"}>
            <h2>Lo sentimos, página no encontrada.</h2>
            <a href={"/"} className={"btn btn-filled mt-5 always-active btn-no-arrow"}>Ir a inicio</a>
        </Container>}
        <PluginChat/>
        <Footer/>
    </div>);
}

export default VerNoticia;