import {Col, Container, Row} from "react-bootstrap";
import Contador from "./Contador";
import "./Contador.scss";
import React from "react";

const Almacen = () => {
    return <div id={"counter-strip"} className={"almacen"}>
        <Container className={"py-2 py-md-5"}>
            <Row className={"d-flex flex-row-reverse"}>
                <Col xs={8} md={6} lg={5} className={"py-3 px-4 p-md-2 p-lg-5"}>
                    <Row>
                        <Col className={"p-md-0"}>
                            <h2 className={"px-md-2"}>Almacén</h2>
                            <p className={"px-md-2"}>Almacén localizado en San Diego, autorizado como Zona Libre de Comercio (FTZ / Inbond), para distribución y liberación de embarques marítimos y aéreos.</p>
                        </Col>
                    </Row>
                    <Row className={"text-center justify-content-end"}>
                        <Col md={11} className={"px-md-0"}>
                            <Row>
                                <Col>
                                    <Contador duration={3} number={"40000"} description={"SqFt en Almacén"} />
                                </Col>
                                <Col>
                                    <Contador duration={3} number={"60"} description={"Usuarios activos"}/>
                                </Col>
                                <Col md={3} className={"mr-md-0 pr-md-0"}>
                                    <Contador duration={3} number={"20"} description={"Andenes"}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Almacen;
