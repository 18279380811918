import {Col, Container, Row} from "react-bootstrap";
import Contador from "../grupo/Contador";
import React from "react";
import "./QuienesSomos.scss";
import {UseViewPort} from "../../../hooks/useViewPort";
import badge from "./../../../images/transporte/24anos-contigo.png";
import logo from "./../../../images/transporte/logo-transporte-pagina.png";
import trailer from "./../../../images/transporte/trailer-it.png";

const QuienesSomosBlock = () => {
    return <React.Fragment>
        <Row className={"quienes-somos-main"}>
            <Col xs={12}>
                <h3>
                    ¿Quiénes somos?
                </h3>
                <p className={"text-justify"}>
                    Una empresa de transporte terrestre internacional y nacional, apegada a los lineamientos de
                    seguridad y calidad consignados en nuestra certificación CTPAT/OEA.
                </p>
            </Col>
            <Col xs={12} className={"justify-content-between mb-3"}>
                <Row className={"lugares justify-content-between"}>
                    <Col xs={3} xl={3} className={"mx-0 pr-0"}><h3>Tijuana</h3></Col>
                    <div className={"d-md-block divisor-vertical-yellow transparency"}/>
                    <Col xs={3} xl className={"mx-0 px-0"}><h3>Ensenada</h3></Col>
                    <div className={"d-md-block divisor-vertical-yellow transparency"}/>
                    <Col xs={3} xl={3} className={"mx-0 px-0"}><h3>San Diego</h3></Col>
                </Row>
            </Col>
            <Col xs={12} className={"text-justify"}>
                <p>Nuestro principal enfoque es proveer soluciones de logística que se adapten a las necesidades de
                    nuestros clientes, con el profesionalismo y cobertura que nos caracteriza, ayudamos a conectar a más
                    de 25 clientes a sus destinos.</p>
            </Col>
        </Row>
    </React.Fragment>
}
const QuienesSomos = () => {
    let isMobile = UseViewPort() < 710;

    return <div>
        <div className={"bg-whitesmoke negocio-facil"}>
            <Container className={"p-4 pt-md-4 pt-lg-5 pt-md-0 "}>
                <Row className={"justify-content-between"}>
                    <Col xs={12} md={6} xl={5}>
                        <QuienesSomosBlock/>
                    </Col>
                    <Col xs={5} md={5} xl={5} className={"mx-auto images"}>
                        <img src={logo} alt={"R&R International Transport"} className={"d-block mx-auto mx-md-5 mb-4"} data-aos="fade-left"/>
                        <img src={badge} alt={"24 años contigo"} className={"d-block mx-auto mx-md-5 mb-4"} data-aos="fade-left"/>
                    </Col>
                </Row>
            </Container>
            <div className={"trailer-section"}>
                <Container>
                    <Row className={"justify-content-between"}>
                        <Col xs={6} md={7} className={"position-relative trailer"}>
                            <img src={trailer} alt={"Mover tu negocio es fácil"} className={"w-100"} data-aos="fade-right"/>
                        </Col>
                        <Col xs md={5} className={"text-justify px-md-4"}>
                            <h3 className={"pt-lg-3"}>Mover tu negocio es fácil</h3>
                            <p>Principales puntos de servicio: Tijuana, Ensenada, San Diego, Los Ángeles County, Orange County, San Bernardino County, Inland Empire County y Utah.</p>
                            <a href="/international-transport/cotizacion" target="_self" className={"btn-filled mb-3 d-block w-auto"}>Solicita asesoría</a>
                            {!isMobile ? <p>Nuestra infraestructura está conformada por:</p> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        <div className={"quienes-somos-transport"}>
            <Container className={"py-4 py-md-4 py-lg-5"}>
                {isMobile ? <Row className={"text-center"}>
                    <Col>
                        <p>Nuestra infraestructura está conformada por:</p>
                    </Col>
                </Row> : null}
                <Row>
                    <Col xs={10} md={10} lg={9} className={"mx-auto"}>
                        <Row>
                            <Col xs={12} md>
                                <Contador duration={3} sign={"+"} number={"40"}
                                          description={`EQUIPOS DE <span class="d-block">TRANSPORTE</span>`}/>
                            </Col>
                            <Col xs={3} className={"mx-auto d-md-none"}>
                                <hr/>
                            </Col>
                            <div className={"d-none d-md-block divisor-vertical-yellow transparency"}/>

                            <Col xs={12} md>
                                <Contador duration={3} sign={"+"} number={"24"}
                                          description={`AÑOS DE EXPERIENCIA`}/>
                            </Col>
                            <Col xs={3} className={"mx-auto d-md-none"}>
                                <hr/>
                            </Col>
                            <div className={"d-none d-md-block divisor-vertical-yellow transparency"}/>

                            <Col xs={12} md>
                                <Contador duration={3} sign={"+"} number={"54"}
                                          description={`RUTAS NACIONALES E <span class="d-block">INTERNACIONALES</span>`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
}

export default QuienesSomos;
