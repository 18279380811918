import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {contactHomeSchema} from "../../../validations/ContactValidation";
import {postContact} from "../../../utils/forms";
import logo from "../../../images/contacto/rodriguez.png";
import SuccessMessage from "../../UI/SuccessMessage";

const ContactoShort = () => {
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(null);
    const [errors, setErrors] = useState({});
    const [fieldsValues, setFieldsValues] = useState({});
    const [errorMsg, setErrorMsg] = useState();

    const fields = [
        {
            type: "text",
            name: "fullname",
            required: true,
            rowSize: 6,
            placeholder: "Nombre",
        },
        {
            type: "email",
            name: "email",
            required: true,
            rowSize: 6,
            placeholder: "Correo Electrónico",
        },
        {
            type: "phone",
            name: "phone",
            required: true,
            rowSize: 6,
            placeholder: "Teléfono",
        },
        {
            type: "textarea",
            rows: 4,
            name: "message",
            required: true,
            rowSize: 12,
            validator: "Mensaje"
        }
    ];

    const validateData = async() => {
        try {
            setErrorMsg("");
            await contactHomeSchema.validate(fieldsValues, { abortEarly: false });
            return true;
        } catch (e) {
            let messages = e.errors;

            if(!messages.length) return true;
            let populateErrors = [];
            fields.map((field) => {
                const match = messages.find((element, index) => {
                    let containText = field.validator ?? field.placeholder;
                    if (element.includes(containText)) {
                        return messages[index];
                    }
                    return false;
                });

                if(typeof match === 'string'){
                    populateErrors[field.name] = match;
                }
                return true;
            });

            setErrors(populateErrors);
            setErrorMsg(e.errors.join('. '));
            return false;
        }
    }

    const sendForm = () => {
        setLoading(true);
        return validateData().then( async (response) => {
            if(response){
                fieldsValues.formname = 'contacto_principal';
                fieldsValues.formtitle = 'Contacto Atención personalizada';
                await postContact(fieldsValues).then((result) => {
                    setStatus(true);
                    setFieldsValues({
                        ...fieldsValues,
                        firstname: "",
                        email: "",
                        phone: "",
                        notes: ""
                    });
                }).catch( () => {
                    setErrorMsg('Hubo un error, favor intente nuevamente.');
                }).finally(() => {
                    setLoading(false);
                })
            }else{
                setLoading(false);
            }

        });
    };

    const handleChange = (event) => {
        setFieldsValues({
            ...fieldsValues, [event.target.name]: event.target.value
        });
    }

    const handleFocus = (event) => {
        setErrors({
            ...errors, [event.target.name]: ''
        });

        setErrorMsg(null);
        setStatus(null);
    }

    return <React.Fragment>
        <Row className={"pb-2 mb-4"}>
            <Col className={"contact-box mx-auto"} xs={11} sm={12} lg={12} xl={11}>
                <Row>
                    <Col xs={12} lg={5} xl className={"image d-inline-block"}/>
                    <Col xs={12} lg xl className={"p-3 p-md-4 d-flex flex-column justify-content-between"}>
                        {
                            status === true && <div className={"my-5"}>
                                <SuccessMessage logo={logo} alt={"Rodriguez"}/>
                            </div>
                        }

                        {
                            status !== true && <div>
                                <h3 className={"text-yellow"}>Atención personalizada</h3>
                                <p>Tu opinión es importante para nosotros, escríbenos
                                    para atender tu solicitud.</p>

                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Control name={"fullname"} placeholder="Nombre"  onChange={handleChange} onFocus={handleFocus} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Control type={"numeric"} name={"phone"} placeholder="Teléfono"  onChange={handleChange} onFocus={handleFocus} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Control name={"email"} placeholder="Correo Electrónico"  onChange={handleChange} onFocus={handleFocus} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Control as="textarea" name={"message"} rows={3} placeholder="Mensaje" onChange={handleChange} onFocus={handleFocus} />
                                    </Form.Group>
                                </>

                                {status === false && <div className="alert alert-danger" role="alert">
                                    Hubo un error, favor de intentar nuevamente.
                                </div>}

                                { errorMsg && <div className="alert alert-danger" role="alert">
                                    {errorMsg}
                                </div>}
                                <button className={"btn-filled mb-0"} onClick={() => { sendForm(); }}>
                                    {loading ? 'Enviando...' : 'Enviar'}
                                </button>
                            </div>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    </React.Fragment>
}

export default ContactoShort;
